<script lang='ts'>
  import InputGroup from './InputGroup.svelte';
  import InputField from './InputField.svelte';
  import { createEventDispatcher, getContext, onMount, setContext } from 'svelte';
  import { formatBirthday } from '../utils/formatBirthday';
  import { deriveBirthday } from '../utils/deriveBirthday';
  import { format } from 'date-fns';
  import { validateDateOfBirth, ValidateDateOfBirth } from '../utils/validateBirthday';
    import sanitizeStreetNameNumber from '../utils/sanitizeStreetNameNumber';

  const dispatch = createEventDispatcher();

  export let mainInsuredAllowed = false;
  export let prefilled: { policyHolder: any, mainInsured: any };
  export let policyHolderPhoneNumber = '';
  export let mainInsuredPhoneNumber = '';
  export let mainInsuredPersonText: Record<string, string>;
  export let policyHolderText: Record<string, string>;
  export let redirecting = false;
  export let hasMainInsuredPerson = false;

  const translate = getContext('translate');
  const getSetting = getContext('setting');
  const getFormFieldSetting = getContext('formFieldSettings');
  const lang = getContext('language')();
  const keepBirthdayEditable = getContext('keepBirthdayEditable');

  const translatedPolicyHolderText =  policyHolderText ? policyHolderText[lang] : undefined;
  const translatedMainInsuredPersonText = mainInsuredPersonText ? mainInsuredPersonText[lang] : undefined;

  let policyHolderDerivedBirthday = '';
  let mainInsuredDerivedBirthday = '';
  let formErrors: { [key: string]: string };
  let debounceTimer;

  const birthday = (person: 'policyHolder' | 'mainInsured') => {
    const prefilledObj = person === 'policyHolder' ? prefilled?.policyHolder : prefilled?.mainInsured;
    if (prefilledObj?.dateOfBirth) {
      // in this case, the birthday is prefilled but should not be editable. The field is kept editable for the special case of birthdays with 01.01.0001
      if (keepBirthdayEditable) {
        return null;
      } else {
        return prefilledObj?.dateOfBirth;
      }
    } else if (getSetting('deriveBirthdayFromPersonalId')) {
      return person === 'policyHolder' ? policyHolderDerivedBirthday : mainInsuredDerivedBirthday;
    } else {
      return null;
    }
  };

  const birthdayValidated = (person: 'policyHolder' | 'mainInsured') => {
    const toValidate = birthday(person);
    validateBirthday({
      id: person === 'policyHolder' ? 'date-of-birth' : 'date-of-birth-mi',
      value: toValidate,
    });
    return toValidate;
  };

  function handlePersonalIdChange(e: Event) {
    const person = e.target.name;
    if (getSetting('deriveBirthdayFromPersonalId')) {
      const derivedBirthday = deriveBirthday(e.target.value);
      if (person === 'policyHolder') {
        policyHolderDerivedBirthday = format(derivedBirthday, 'dd.MM.yyyy');
      } else {
        mainInsuredDerivedBirthday = format(derivedBirthday, 'dd.MM.yyyy');
      }
    }
  }

  function handlePhoneNumberSwitch(e) {
    redirecting = true;
    dispatch('phoneNumberSwitch');
  }

  function validateBirthday(target: { id: string, value: string }) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      const ageConfig = {
        adultMaxAge: parseInt(getSetting('adultMaxAge')),
        mainInsuredMinAge: parseInt(getSetting('mainInsuredMinAge')),
      };
      let validity: ValidateDateOfBirth = validateDateOfBirth(target.value, ageConfig, false);

      if (validity?.valid) {
        formErrors = { ...formErrors, [target.id]: null };
      } else {
        if (validity.error === 'empty') {
          formErrors = { ...formErrors, [target.id]: translate('Please enter a date of birth.') };
        } else if (validity.error === 'format-invalid') {
          formErrors = {
            ...formErrors,
            [target.id]: translate('We cannot handle this format, please enter the date of birth in the format DD.MM.YYYY., e.g. 01.01.2000.'),
          };
        } else if (validity.error === 'age-too-high') {
          formErrors = {
            ...formErrors,
            [target.id]: translate('People over the age of $n1 cannot be added.').replace('$n1', getSetting('adultMaxAge')),
          };
        } else if (validity.error === 'age-too-low') {
          formErrors = {
            ...formErrors,
            [target.id]: translate('The main insured person needs to be at least $n1 or older.').replace('$n1', getSetting('mainInsuredMinAge')),
          };
        } else {
          formErrors = { ...formErrors, [target.id]: validity.error };
        }
      }
      dispatch('setFormLocked', Object.entries(formErrors).filter(([, error]) => error).length >= 1);
    }, 750);
  }

  onMount(() => {
    if (prefilled?.policyHolder?.email) {
      dispatch('policyHolderEmail', prefilled?.policyHolder?.email);
    }
    if (prefilled?.mainInsured?.email) {
      dispatch('mainInsuredEmail', prefilled?.mainInsured?.email);
    }
  });
</script>
<section class='customer-data'>
  <h3 class='base-headline customer-data__headline'>{translate('Policy Holder')}</h3>
  {#if translatedPolicyHolderText}
  <p class='base-headline customer-data__headline'>{translatedPolicyHolderText}</p>
  {/if}

  <div class='customer-data__fields'>
    <InputGroup headline={translate('Basic Information')}>
      <InputField
        label={translate('A1 Mobile Phone Nr.')}
        description={translate('This phone number activates your travel insurance cover.')}
        required={getFormFieldSetting('phoneNumber').required}
        readonly={getFormFieldSetting('phoneNumber').readonly}
        value={policyHolderPhoneNumber}
        id='phone'
        input={{ type: 'tel', name: 'phoneNumber'}}
      />
      {#if getFormFieldSetting('email')}
        <InputField
          label={translate('E-Mail')}
          required={getFormFieldSetting('email').required}
          readonly={getFormFieldSetting('email').readonly}
          value={prefilled?.policyHolder?.email || ''}
          id='email'
          input={{ type: 'email', name: 'email'}}
          on:change={e => dispatch('policyHolderEmail', e.target.value)}
        />
      {/if}
      {#if getSetting('externalPhoneVerificationUrl')}
        <div class='customer-data__different-phone'>
          <button class='button vers--outline' type='button' disabled={redirecting} on:click={handlePhoneNumberSwitch}>
            {#if redirecting}
              { translate('...Redirecting') }
            {:else}
              { translate('Use different phone number') }
            {/if}
          </button>
        </div>
      {/if}
    </InputGroup>
    <InputGroup headline={translate('Personal Data')}>
      {#if getFormFieldSetting('salutation')}
        <InputField
          label={translate('Salutation')}
          required={getFormFieldSetting('salutation').required}
          value={prefilled?.policyHolder?.gender}
          id='title'
          selection={[
                            {label: translate('Mr.'), value: 'Male'},
                            {label: translate('Mrs.'), value: 'Female'},
                            {label: translate('Unknown'), value: 'Unknown'}]}
          input={{ element: "select", type: 'text', name: 'salutation'}}
        />
      {/if}
      {#if getFormFieldSetting('firstName')}
        <InputField
          label={translate('First Name')}
          required={getFormFieldSetting('firstName').required}
          readonly={getFormFieldSetting('firstName').readonly}
          value={prefilled?.policyHolder?.firstName || ''}
          id='first-name'
          input={{ type: 'text', name: 'firstName' }}
        />
      {/if}
      {#if getFormFieldSetting('lastName')}
        <InputField
          label={translate('Surname')}
          required={getFormFieldSetting('lastName').required}
          readonly={getFormFieldSetting('lastName').readonly}
          value={prefilled?.policyHolder?.lastName || ''}
          id='surname'
          input={{ type: 'text', name: 'lastName'}}
        />
      {/if}
      {#if getFormFieldSetting('nationalIdentificationNumber')}
        <InputField
          label={`${translate('Personal ID')} / ${getSetting('personalIdTerm')} `}
          required={getFormFieldSetting('nationalIdentificationNumber').required}
          readonly={getFormFieldSetting('nationalIdentificationNumber').readonly}
          value={prefilled?.policyHolder?.nationalIdentificationNumber}
          id='personal-id'
          input={{ type: 'number', name: 'nationalIdentificationNumber' }}
          on:change={handlePersonalIdChange}
        />
      {/if}
      {#if getFormFieldSetting('dateOfBirth')}
        <InputField
          label={translate('Date of Birth')}
          description={translate('Birthday, eg. 01.12.1979')}
          required={getFormFieldSetting('dateOfBirth').required}
          readonly={getFormFieldSetting('dateOfBirth').readonly && !keepBirthdayEditable}
          value={birthdayValidated('policyHolder')}
          errors={formErrors?.['date-of-birth']}
          on:keyup={e => validateBirthday(e.target)}
          id='date-of-birth'
          input={{ type: 'text', name: 'dateOfBirth', pattern: '^([0]?[1-9]|[1|2][0-9]|[3][0|1])[.]([0]?[1-9]|[1][0-2])[.]([0-9]{4}|[0-9]{2})$'}}
        />
      {/if}
      {#if getFormFieldSetting('passportNumber')}
        <InputField
          label={translate('Passport Number')}
          required={getFormFieldSetting('passportNumber').required}
          readonly={getFormFieldSetting('passportNumber').readonly}
          value={prefilled?.policyHolder?.passport.passportNumber || ''}
          id='passport-number'
          input={{ type: 'text', name: 'passportNumber'}}
        />
      {/if}
    </InputGroup>
    <InputGroup headline={translate('Address in $n').replace('$n', getSetting('countryName')[lang])}>
      {#if getFormFieldSetting('street')}
        <InputField
          label={translate('Street')}
          description={translate('Street name and number, residence in $n required.').replace('$n', getSetting('countryName')[lang])}
          required={getFormFieldSetting('street').required}
          readonly={getFormFieldSetting('street').readonly}
          value={
                prefilled
                ? sanitizeStreetNameNumber(prefilled.policyHolder?.address?.addressLine1, prefilled.policyHolder?.address?.addressLine2)
                : null}
          id='street'
          input={{ type: 'text', name: 'street'}}
        />
      {/if}
      {#if getFormFieldSetting('postalCode')}
        <InputField
          label={translate('Postal Code')}
          required={getFormFieldSetting('postalCode').required}
          readonly={getFormFieldSetting('postalCode').readonly}
          value={prefilled?.policyHolder?.address?.postalCode || ''}
          id='postal-code'
          input={{ type: 'number', name: 'postalCode'}}
        />
      {/if}
      {#if getFormFieldSetting('city')}
        <InputField
          label={translate('City')}
          required={getFormFieldSetting('city').required}
          readonly={getFormFieldSetting('city').readonly}
          value={prefilled?.policyHolder?.address?.city || ''}
          id='city'
          input={{ type: 'text', name: 'city'}}
        />
      {/if}
    </InputGroup>
  </div>
  {#if mainInsuredAllowed}
    <div class='main-insured-checkbox'>
      <label class='main-insured-text'>
        <input type='checkbox' bind:checked={hasMainInsuredPerson}>
        {translate('add main insured person')}
      </label>
    </div>
  {/if}
  {#if hasMainInsuredPerson}
    <h3 class='base-headline customer-data__headline'>{translate('Main Insured Person')}</h3>
    {#if translatedMainInsuredPersonText}
      <p class='base-headline customer-data__headline'>{translatedMainInsuredPersonText}</p>
    {/if}
    <div class='customer-data__fields'>
      <InputGroup headline={translate('Basic Information')}>
        <InputField
          label={translate('A1 Mobile Phone Nr.')}
          description={translate('This phone number activates your travel insurance cover.')}
          required={getFormFieldSetting('phoneNumber').required}
          value={mainInsuredPhoneNumber}
          id='phone'
          input={{ type: 'tel', name: 'phoneNumber'}}
        />
        {#if getFormFieldSetting('email')}
          <InputField
            label={translate('E-Mail')}
            required={getFormFieldSetting('email').required}
            value={prefilled?.mainInsured?.email}
            id='email-mi'
            input={{ type: 'email', name: 'emailMi'}}
            on:change={e => dispatch('mainInsuredEmail', e.target.value)}
          />
        {/if}
        {#if getSetting('externalPhoneVerificationUrl')}
          <div class='customer-data__different-phone'>
            <button class='button vers--outline' type='button' disabled={redirecting}
                    on:click={handlePhoneNumberSwitch}>
              {#if redirecting}
                { translate('...Redirecting') }
              {:else}
                { translate('Use different phone number') }
              {/if}
            </button>
          </div>
        {/if}
      </InputGroup>
      <InputGroup headline={translate('Personal Data')}>
        {#if getFormFieldSetting('salutation')}
          <InputField
            label={translate('Salutation')}
            required={getFormFieldSetting('salutation').required}
            value={prefilled?.mainInsured?.gender}
            id='title-mi'
            selection={[
                            {label: translate('Mr.'), value: 'Male'},
                            {label: translate('Mrs.'), value: 'Female'},
                            {label: translate('Unknown'), value: 'Unknown'}]}
            input={{ element: "select", type: 'text', name: 'salutationMi'}}
          />
        {/if}
        {#if getFormFieldSetting('firstName')}
          <InputField
            label={translate('First Name')}
            required={getFormFieldSetting('firstName').required}
            value={prefilled?.mainInsured?.firstName || ''}
            id='first-name-mi'
            input={{ type: 'text', name: 'firstNameMi' }}
          />
        {/if}
        {#if getFormFieldSetting('lastName')}
          <InputField
            label={translate('Surname')}
            required={getFormFieldSetting('lastName').required}
            value={prefilled?.mainInsured?.lastName || ''}
            id='surname-mi'
            input={{ type: 'text', name: 'lastNameMi'}}
          />
        {/if}
        {#if getFormFieldSetting('nationalIdentificationNumber')}
          <InputField
            label={`${translate('Personal ID')} / ${getSetting('personalIdTerm')} `}
            required={getFormFieldSetting('nationalIdentificationNumber').required}
            value={prefilled?.mainInsured?.nationalIdentificationNumber}
            id='personal-id-mi'
            input={{ type: 'number', name: 'nationalIdentificationNumberMi' }}
            on:change={handlePersonalIdChange}
          />
        {/if}
        {#if getFormFieldSetting('dateOfBirth')}
          <InputField
            label={translate('Date of Birth')}
            description={translate('Birthday, eg. 01.12.1979')}
            required={getFormFieldSetting('dateOfBirth').required}
            value={birthdayValidated('mainInsured')}
            errors={formErrors?.['date-of-birth-mi']}
            on:keyup={e => validateBirthday(e.target)}
            id='date-of-birth-mi'
            input={{ type: 'text', name: 'dateOfBirthMi', pattern: '^([0]?[1-9]|[1|2][0-9]|[3][0|1])[.]([0]?[1-9]|[1][0-2])[.]([0-9]{4}|[0-9]{2})$'}}
          />
        {/if}
      </InputGroup>
      <InputGroup headline={translate('Address in $n').replace('$n', getSetting('countryName')[lang])}>
        {#if getFormFieldSetting('street')}
          <InputField
            label={translate('Street')}
            description={translate('Street name and number, residence in $n required.').replace('$n', getSetting('countryName')[lang])}
            required={getFormFieldSetting('street').required}
            value={
                prefilled
                ? sanitizeStreetNameNumber(prefilled.mainInsured?.address?.addressLine1, prefilled.mainInsured.address?.addressLine2)
                : null}
            id='street-mi'
            input={{ type: 'text', name: 'streetMi'}}
          />
        {/if}
        {#if getFormFieldSetting('postalCode')}
          <InputField
            label={translate('Postal Code')}
            required={getFormFieldSetting('postalCode').required}
            alue={prefilled?.mainInsured?.address?.postalCode || ''}
            id='postal-code-mi'
            input={{ type: 'number', name: 'postalCodeMi'}}
          />
        {/if}
        {#if getFormFieldSetting('city')}
          <InputField
            label={translate('City')}
            required={getFormFieldSetting('city').required}
            value={prefilled?.mainInsured?.address?.city || ''}
            id='city-mi'
            input={{ type: 'text', name: 'cityMi'}}
          />
        {/if}
      </InputGroup>
    </div>
  {/if}
  <slot />
</section>
<style lang='scss' scoped>
  .customer-data {
    &__headline {
      margin-block-end: var(--space-l);
    }

    &__different-phone {
      align-self: center;
      justify-self: end;
    }
  }

  .main-insured-checkbox {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .main-insured-text {
    font-size: 14pt;
  }
</style>
